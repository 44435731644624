<template>
	<div id="index">
		<el-container>
			<el-header>
				<div class="headerBox">
					<el-row>
						<el-col :span="4">
							<div class="grid-content bg-purple logo">
								<img src="../../assets/img/logo.jpg" alt="">
							</div>
						</el-col>
						<el-col :span="13">
							<div class="grid-content bg-purple-light menu">
								<ul>
									<li @click="goIntrodunce">業務フロー</li>
								</ul>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple loginBox">
								<span @click="goLogin" class="login">ログイン</span>
								<span @click="goSign" class="sign">新規登録</span>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-header>
			<el-main>
				<div class="banner">
					<el-carousel :interval="5000" arrow="always" height="600px">
						<el-carousel-item v-for="item in bannerImg" :key="item.id">
							<img :src="item.url" :alt="item.title">
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="box1">
					<div class="content">
						<div class="title">
							<span>マキマキのビジョン</span>
							<i></i>
						</div>
						<div class="text">
							「マンガ創りに集中できる環境を生み出す!」
						</div>
						<div class="img-box">
							<div class="left">
								<span>漫画家と編集者のアンマッチを解消</span>
							</div>
							<div class="center"><span>複数人での共同製作の効率アップ</span></div>
							<div class="right"><span>マンガ業界の人口増加と収益構造のアップデート</span></div>
						</div>
					</div>
				</div>
				<div class="box2">
					<div class="content">
						<div class="top-title">
							<i class="icon"></i>
							<div class="title">
								<span>マキマキでできること</span>
								<i></i>
							</div>
						</div>
						<div class="computer">
							<div class="list">
								<ul>
									<li v-for="(item, index) in serData" :key="index">
										<i><img :src="item.cionurl" alt=""></i>
										<span>{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="box3">
					<div class="content">
						<div class="wrap">
							<div class="one">
								<div class="top">
									<span>マッチング機能</span>
								</div>
								<p>
									漫画家と編集者、漫画家とアシスタントなど
									登録ユーザーの情報をもとにシステムが自動でマッチングを行います
								</p>
							</div>
						</div>
						<div class="wrap">
							<div class="two">
								<div class="top">
									<span class="two">プロジェクト<br>管理機能</span>
								</div>
								<p>
									プロジェクトに集まったチームメンバーの間でタスク割り振りや作業進捗を可視化し、協業をスムーズにすることができます
								</p>
							</div>
						</div>
						<div class="wrap">
							<div class="three">
								<div class="top">
									<span>メンバー募集機能</span>
								</div>
								<p>
									企画段階と製作段階でそれぞれメンバーを募ることができ、応募者のプロフィールやポートフォリオをもとに、自分のプロジェクトに適したメンバーを探すことができます
								</p>
							</div>
						</div>
						<div class="wrap">
							<div class="four">
								<div class="top">
									<span class="four">収益分配機能 <br>（coming soon）</span>
								</div>
								<p>
									電子配信を通して得られた収益を、携わるメンバー全員に自動で報酬分配することができます
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="box4">
					<div class="content">
						<div class="title">
							<i></i>
							<span>マキマキのある未来で起きること</span>
						</div>
						<div class="text-img">
							<div class="imgOne">
								<div>
									<i></i>
									<div>
										<p>さまざまな人材が活躍できる</p>
										<span>
											漫画家や作画アシスタントのみならず、キャラクターデザインや背景デザイン、進捗管理マネージャーなど、多様な役割を設けることで、より分業化されさまざまな人材がマンガ創りの現場で活躍できるようになります
										</span>
									</div>
								</div>
								<p>
									<img src="../../assets/img/pcimg/tuan1-1.png" alt="">
								</p>
							</div>
							<div class="imgTwo">
								<p>
									<img src="../../assets/img/pcimg/tuan2-1.png" alt="">
								</p>
								<div>
									<i></i>
									<div>
										<p>収益分配構造のアップデート</p>
										<span>
											「すべてのメンバーに印税を」<br>
											マキマキで提供予定の「収益分配機能」ではプロジェクトごとに分配ルールを設定することができ、作品に対する収益をオンライン上で自動で振り分ける仕組みを提供します。（coming soon）
										</span>
									</div>
								</div>
							</div>
							<div class="imgThree">
								<div>
									<i></i>
									<div>
										<p>マッチングの最適化</p>
										<span>
											<!-- 先帝创业未半而中道崩殂，今天下三分，益州疲弊，慈城危
											急存亡之秋也，然市委之臣不屑于内，中指只是，网神域歪着，改锥贤弟。 -->
											ユーザーの登録情報やシステムの利用実績をもとに
											マッチ度の高いパートナーや、プロジェクトなどを自動でお勧めします。
										</span>
									</div>
								</div>
								<p>
									<img src="../../assets/img/pcimg/tuan3-1.png" alt="">
								</p>
							</div>
							<div class="imgFour">
								<p>
									<img src="../../assets/img/pcimg/tuan4-1.png" alt="">
								</p>
								<div>
									<i></i>
									<div>
										<p>オンライン分業に改革を</p>
										<span>
											チーム制作の際の分業管理について、柔軟にカスタマイズ可能になります。
											作画チェックのための機能や、進捗管理の機能も提供します。
											制作物は工程ごとに履歴管理され、万が一の手戻りが発生しても作業前のデータに戻すことができます。
											作業中のロック機能などもあり、安心して協業ができます。
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</el-main>
			<div class="box-bottom">
				<div>
					<span>Copyright  2022 トライ・ドリーム株式会社</span>
					<div>
						<img :src="cionurl" alt="">
						<img :src="cionur2" alt="">
					</div>
				</div>
			</div>
		</el-container>
	</div>
</template>

<script>
export default {
	name: "home",
	data() {
		return {
			bannerImg: [
				
				{ url: require('../../assets/img/banner/2.jpg'), title: "333", id: 3 }
			],
			serData: [
				{
					cionurl: require('../../assets/img/pcimg/iconimg1-1.png'),
					text: "マッチング機能"
				},
				{
					cionurl: require('../../assets/img/pcimg/iconimg2-1.png'),
					text: "プロジェクト管理機能"
				},
				{
					cionurl: require('../../assets/img/pcimg/iconimg3-1.png'),
					text: "メンバー募集機能"
				},
				{
					cionurl: require('../../assets/img/pcimg/iconimg4-1.png'),
					text: "収益分配機能（coming soon）"
				}
			],

			cionurl: require('../../assets/img/pcimg/iconimg1-1.png'),
			cionur2: require('../../assets/img/pcimg/iconimg2-1.png'),
		}
	},
	mounted() {
		this.scrollToTop()
		
	},

	methods: {
		scrollToTop(){
        	window.scrollTo(0,0)
    	},
		handleSelect(item) {

		},
		goSign() {
			this.$router.push('/sign')
		},
		goLogin() {
			this.$router.push('/login')
		},
		goIntrodunce() {
			this.$router.push('/introduce')
		}
	},

}
</script>
<style lang="scss" scoped>
#index {
	width: 100%;
	height: auto;

	.el-container {
		.el-header {
			height: 110px !important;
			padding: 0;

			.headerBox {
				width: 80%;
				margin: 0 auto;
				flex-wrap: nowrap;
				align-items: center;
				height: 100%;
				line-height: 100%;

				.el-row {
					height: 100%;

					>div {
						height: 100%;

						.logo {
							display: flex;
							align-items: center;
							height: 100%;

							img {
								width: 50%;
								// object-fit: none;
							}
						}

						.menu {
							display: flex;
							align-items: center;
							height: 100%;
							justify-content: flex-end;

							ul {
								display: flex;
								align-items: center;

								li {
									@media (max-width:1024px) {
										font-size: 16px;
										padding: 0 22px;
									}

									font-size: 20px;
									padding: 0 35px;
									color: #1875d2;
									cursor: pointer;

									i {
										font-size: 28px;
									}
								}
								li:hover {
									color: #409eff;
								}
							}
						}

						.loginBox {
							display: flex;
							align-items: center;
							justify-content: flex-end;
							height: 100%;

							span {
								@media (max-width:1024px) {
									font-size: 16px;
									height: 35px;
									line-height: 35px;
									width: 80px;
								}

								display: block;
								width: 124px;
								height: 50px;
								border-radius: 25px;
								text-align: center;
								line-height: 50px;
								font-size: 20px;
								cursor: pointer;
							}

							.login {
								background: #F03747;
								color: #fff;
								margin-right: 20px;
							}

							.sign {
								background: #FFFFFF;
								border: 2px solid #F03747;
								color: #F03747;
							}
						}

						.search {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;

							i {
								font-size: 28px;
								cursor: pointer;
							}
						}
					}
				}
			}
		}

		.el-main {
			padding: 0;

			.el-carousel__container {
				img {
					width: 100%;
					height: 100%;
				}
			}

			.box1 {
				background: url(../../assets/img/pcimg/topimg25\.png) center no-repeat;
				background-size: 100% 100%;
				height: 600px;
				overflow: hidden;

				.content {
					width: 80%;
					margin: 0 auto;
					position: relative;

					.title {
						position: relative;
						margin-top: 110px;

						span {
							display: block;
							text-align: center;
							font-size: 35px;
							position: relative;
							z-index: 100;
							letter-spacing: 2px;
							font-weight: 540;
							color: #000;
						}

						i {
							width: 350px;
							height: 15px;
							display: block;
							border-radius: 20px;
							background-color: rgb(235, 235, 235);
							position: absolute;
							top: 28px;
							left: 50%;
							margin-left: -175px;
						}
					}

					.text {
						text-align: center;
						margin-top: 25px;
						font-size: 25px;
						letter-spacing: 6px;
					}

					.img-box {
						display: flex;
						justify-content: space-between;
						margin-top: 50px;

						>div {
							height: 300px;
							width: 100%;
							display: flex;
							position: relative;
							display: flex;
							justify-content: center;

							span {
								position: absolute;
								bottom: -20px;
								display: block;
								text-align: center;
								letter-spacing: 2px;
								font-size: 15px;
								font-weight: 600;
								// left: 50%;
								// margin: auto;
								//    transform:translate (-50%,50%);
							}
						}

						.left {
							background: url("../../assets/img/pcimg/imgleft.jpg");
							background-size: 70%;
							background-repeat: no-repeat;
							background-position-y: center;
							background-position-x: center;
						}

						.center {
							background: url("../../assets/img/pcimg/imgtop1-1.png");
							background-size: 67%;
							background-repeat: no-repeat;
							background-position-y: center;
							background-position-x: center;
						}

						.right {
							background: url("../../assets/img/pcimg/imgtop3-1.png");
							background-size: 70%;
							background-repeat: no-repeat;
							background-position-y: center;
							background-position-x: center;
						}
					}
				}
			}

			.box2 {
				background: url(../../assets/img/pcimg/bottomimg25-2\.png) center no-repeat;
				background-size: 100% 100%;
				height: 750px;
				overflow: hidden;

				.content {
					width: 80%;
					margin: 0 auto;
					position: relative;
					margin-top: 70px;
					.top-title {
						text-align: center;

						.icon {
							display: block;
							background: url("../../assets/img/pcimg/bottom-3.png");
							background-size: 70%;
							background-repeat: no-repeat;
							background-position-y: center;
							background-position-x: center;
							width: 100px;
							height: 40px;
							margin: 0 auto;
						}

						.title {
							position: relative;
							margin-top: 20px;

							span {
								display: block;
								text-align: center;
								font-size: 35px;
								position: relative;
								z-index: 100;
								letter-spacing: 2px;
								font-weight: 540;
								color: #000;
							}

							i {
								width: 400px;
								height: 15px;
								display: block;
								border-radius: 20px;
								background-color: rgb(235, 235, 235);
								position: absolute;
								top: 28px;
								left: 50%;
								margin-left: -200px;
							}
						}
					}

					.computer {
						background: url("../../assets/img/pcimg/computer-1.png");
						background-repeat: no-repeat;
						height: 540px;
						// width: 100%;
						background-size: 58%;
						margin-top: 20px;
						position: relative;

						.list {
							position: relative;
							right: -56%;
							top: 80px;

							ul {
								li {
									margin-bottom: 15px;
									display: flex;
									align-items: center;

									i {
										text-align: center;

										img {
											width: 75%;
										}
									}

									span {
										margin-left: 10px;
										font-weight: 600;
										letter-spacing: 2px;
										font-size: 20px;
										color: rgb(75, 75, 75);
									}
								}
							}
						}
					}
				}
			}

			.box3 {
				background-color: rgb(241, 55, 71);
				overflow: hidden;

				.content {
					display: flex;
					justify-content: space-between;
					width: 80%;
					margin: 0 auto;
					padding: 150px 0;

					.wrap {
						width: 100%;

						>div {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
						}

						div.top {
							width: 135px;
							height: 135px;
							// border-radius: 50%;
							// border: 3px solid #fff;
							text-align: center;
							position: relative;

							span {
								color: #fff;
								font-size: 12px;
								margin-top: 25px;
								display: block;
							}

							span.two {
								margin-top: 15px;
							}

							span.four {
								margin-top: 15px;
							}

							i {
								display: block;
								width: 80px;
								height: 80px;
							}
						}
						p {
							text-align: center;
							color: #fff;
							font-size: 12px;
							line-height: 20px;
							letter-spacing: 1px;
							margin-top: 20px;
							width: 90%;
						}
							.one .top {
								background: url("../../assets/img/pcimg/1.png");
								background-size: 100%;
								background-repeat: no-repeat;
							}

							.two .top {
								background: url("../../assets/img/pcimg/2.png");
								background-size: 100%;
								background-repeat: no-repeat;
								
							}

							.three .top {
								background: url("../../assets/img/pcimg/3.png");
								background-size: 100%;
								background-repeat: no-repeat;
								
							}

							.four .top {
								background: url("../../assets/img/pcimg/4.png");
								background-size: 100%;
								background-repeat: no-repeat;
							}
					}
				}
			}

			.box4 {
				background: url(../../assets/img/pcimg/bottomImg1.png);
				background-repeat: no-repeat;
				background-size: 100%;
				background-position-y: bottom;
				padding-bottom: 125px;

				.content {
					width: 80%;
					margin: 0 auto;

					.title {
						margin-top: 50px;
						i {
							display: block;
							width: 70px;
							height: 7px;
							border-radius: 5px;
							background-color: rgb(241, 55, 71);
						}
						span {
							display: block;
							margin-top: 5px;
							display: block;
							font-size: 35px;
							letter-spacing: 2px;
							font-weight: 540;
							color: #000;
						}
					}
					.text-img {
						margin-top: 50px;
						>div {
							display: flex;
							justify-content: space-between;
							>div {
								width: 60%;
								display: flex;
								margin-top: 60px;
								i {
									display: block;
									width: 100px;
									height: 100px;
								}
								>div {
									flex: 1;
									margin-left: 20px;
										p {
											font-weight: 600;
											letter-spacing: 3px;
											font-size: 20px;
											color: #4b4b4b;
										}
										span {
											display: block;
											margin-top: 10px;
											font-size: 16px;
											letter-spacing: 5px;
											color: #4b4b4b;
											line-height: 24px;
										}
								}
							}
							>p {
								width: 40%;
								display: flex;
								justify-content: end;
								img {
								}
							}
						}
						.imgOne {
							>div {
								i {
									background: url("../../assets/img/pcimg/01.png");
									background-repeat: no-repeat;
									background-size: 100%;
								}
							}
						}
						.imgTwo {
							>div {
								i {
									background: url("../../assets/img/pcimg/02.png");
									background-repeat: no-repeat;
									background-size: 100%;
								}
							}
						}
						.imgThree {
							>div {
								i {
									background: url("../../assets/img/pcimg/03.png");
									background-repeat: no-repeat;
									background-size: 100%;
								}
							}
						}
						.imgFour {
							>div {
								i {
									background: url("../../assets/img/pcimg/04.png");
									background-repeat: no-repeat;
									background-size: 100%;
								}
							}
						}
					}
				}
				
			}
		}
	}

	.box-bottom {
		display: flex;
		width: 100%;
		padding: 20px 0;
		background-color: #333;

		>div {
			width: 80%;
			display: flex;
			margin: 0 auto;
			justify-content: space-between;
			align-items: center;
			color: #ffff;

			img:first-child {
				margin-right: 20px;
			}

		}
	}
}

.el-carousel__item h3 {
	color: #475669;
	font-size: 18px;
	opacity: 0.75;
	line-height: 300px;
	margin: 0;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
}</style>